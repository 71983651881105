import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Menü | The Mellow Malt Menü
			</title>
			<meta name={"description"} content={"Rahatlamanın Lezzetlerini Tadın"} />
			<meta property={"og:title"} content={"Menü | The Mellow Malt Menü"} />
			<meta property={"og:description"} content={"Rahatlamanın Lezzetlerini Tadın"} />
			<meta property={"og:image"} content={"https://fronstailoc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fronstailoc.com/img/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fronstailoc.com/img/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fronstailoc.com/img/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				max-width="520px"
				margin="0 auto 5px auto"
				text-align="center"
			>
				Menü
			</Text>
			<Text
				as="p"
				color="--grey"
				max-width="520px"
				margin="0 auto"
				text-align="center"
				font="--lead"
			>
				Her yemeğin ve içeceğin rahatlık ve sohbet anlarınızı tamamlamak için hazırlandığı The Mellow Malt'ın mutfak kalbine hoş geldiniz.
			</Text>
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://fronstailoc.com/img/7.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						İç Açıcı Atıştırmalıklar
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						olmak üzere pub klasiklerinden oluşan sıcak bir topluluk.
						<br />
						<br />
						- Peynir ve Şarküteri Tabağı: Kaliteli peynirler ve kurutulmuş etlerden oluşan seçkin bir seçki, rahat sohbetlerde paylaşmak için idealdir.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://fronstailoc.com/img/10.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Crafty Ales & Brews
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						- Yerel Taslaklar: Her biri kendine özgü bir karaktere sahip yerel el yapımı biralardan oluşan dönüşümlü bir seçkiyi keşfedin.
						<br />
						<br />
						- The Mellow Malt Signature Ale: Gevşemek ve yumuşak anların tadını çıkarmak için mükemmel, eve özel bir bira.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://fronstailoc.com/img/8.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Alkolsüz Lezzetler
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						- Zanaatkar Kahveleri ve Çayları: Ruh halinize uygun çeşitli özel kahveler ve rahatlatıcı çaylar arasından seçim yapın.
						<br />
						<br />
						- Hazırlanmış Mocktailler: Ferahlatıcı ve lezzetli mocktaillerimiz, vızıltı olmadan duyular için bir zevktir.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://fronstailoc.com/img/9.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="--headline3" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Tatlı Fısıltılar
						<br />
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						- Sıcak Elmalı Crumble: Bir top vanilyalı dondurma ile servis edilen klasik bir tatlı, sıcacık akşamlar için mükemmeldir.
						<br />
						<br />
						- Çikolatalı Lav Kek: Çökmekte olan çikolatalı kekimizin zengin, akıcı sıcaklığıyla kendinizi şımartın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Sadece Başlangıç
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Bu menü, The Mellow Malt'ta sizi nelerin beklediğine dair yalnızca bir fikir vermektedir. Çeşitli diyet tercihlerine hitap ediyoruz ve sahip olabileceğiniz özel ihtiyaçları karşılamaktan mutluluk duyuyoruz. Daha ayrıntılı bilgi almak veya diyet gereksinimlerinize uygun seçenekler hakkında bilgi almak için bize ulaşmaktan çekinmeyin.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 500 32px/1.2 --fontFamily-serifTimes"
						padding="0px 50px 0px 0px"
						lg-margin="0px 0px 50px 0px"
						md-padding="0px 0 0px 0px"
						md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
						sm-margin="0px 0px 35px 0px"
					>
						Bir masa rezervasyonu:
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Telefon
							</Text>
							<Link href="tel:+90 213 432 1532" color="#000000" text-decoration-line="initial" font="--lead">
								+90 213 432 1532
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:contact@fronstailoc.com" color="#000000" text-decoration-line="initial" font="--lead">
								contact@fronstailoc.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});